<template>
  <div class="csn-mmg" :class="{ 'csn-mm-five': !isCasinoPlatin }">
    <section class="section-menu-buttons">
      <div class="row-fluid">
        <div class="col-md-6 csn-mm-header">
          <a @click="returnToMainMenu">
            <SlideArrow :isBack="true" />
          </a>
        </div>
        <div class="col-md-6 text-right csn-mm-header">
          <a class="close-btn" @click="TOGGLE_MAIN_MENU">
            <CloseIcon />
          </a>
        </div>
      </div>
    </section>

    <div class="main-menu csn-mm-list">
      <ul>
        <li
          v-for="(name, key) in language"
          :key="key"
          @click="changeLanguage(key)"
        >
          <a class="csn-mm-list-route">{{ name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  Module,
  MAIN_MENU_LANGUAGE,
  TOGGLE_MAIN_MENU,
  TOGGLE_MAIN_MENU_COMPONENT,
  MAIN_MENU,
  CASINO_PLATIN,
} from '@/constants'
import { Language as LanguageEnum } from '@/constants'
import { navigateTo } from '@/helpers'

export default {
  name: MAIN_MENU_LANGUAGE,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  computed: {
    language: () => LanguageEnum,
    selectedLanguage() {
      return this.$i18n.locale.toUpperCase()
    },
    isCasinoPlatin: () => process.env.VUE_APP_THEME === CASINO_PLATIN,
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_MAIN_MENU,
      TOGGLE_MAIN_MENU_COMPONENT,
    ]),
    returnToMainMenu() {
      this.TOGGLE_MAIN_MENU_COMPONENT({ value: MAIN_MENU })
    },
    defineIsSelected: ({ key, locale }) => key === locale,
    changeLanguage(key) {
      const currentRoute = this.$router.history.current
      this.$i18n.locale = key

      this.TOGGLE_MAIN_MENU()
      navigateTo({
        name: currentRoute.name,
        params: { ...currentRoute.params, lang: key },
      })
    },
  },
}
</script>
